
///require("dotenv")

import { createApp, h } from "vue";
import { createWebHistory, createRouter } from "vue-router";
import axios from "axios";

import store from "@/stores";

// import App from './App.vue'
import App from './SuspenserApp.vue'

import Admin from "@/layout/AdminLayout.vue";
import Hello from "@/pages/HelloView.vue";

import NewEditor from "@/layout/EditorLayout.vue";


import Table from "@/pages/TableView.vue";
import TableNew from "@/pages/TableViewNew.vue";

import TableCollection from "@/pages/TableViewCollection.vue";

import Week from "@/pages/WeekView.vue";
import ChartEditor from "@/pages/ChartEditorView.vue";
import Media from "@/pages/MediaView.vue";

import Profile from "@/pages/ProfileView.vue";

import Network from "@/pages/NetworkView.vue";
import Form from "@/pages/FormView.vue";
import FormEditor from "@/pages/FormEditorView.vue";


import Wizard from "@/pages/WizardView.vue";


import Result from "@/pages/ResultView.vue";
import Flow from "@/pages/FlowView.vue";
import FlowLCA from "@/pages/FlowViewLCA.vue";

import MapMarker from "@/pages/MapMarkerView.vue";
import MapHeatMap from "@/pages/MapHeatView.vue";
import Page from "@/pages/PageView.vue";
import Kanban from "@/pages/KanbanView.vue";
import KanbanDynamic from "@/pages/KanbanViewDynamic.vue";

import DashboardKanban from "@/pages/DashboardKanbanView.vue";
import Agenda from "@/pages/AgendaView.vue";
import TestScreenSize from "@/pages/TestScreenSize.vue";

import QueryView from "@/pages/QueryView.vue";

import Diagram from "@/pages/DiagramView.vue";

import Document from "@/pages/DocumentView.vue";
import DocumentDisplay from "@/pages/DocumentDisplayView.vue";

import DeveloperForm from "@/pages/DeveloperFormView.vue";
import DeveloperList from "@/pages/DeveloperListView.vue";
// import { ProCalendar } from "vue-pro-calendar";
import PageEditor from "@/pages/PageEditorView.vue";
import PageEditorBody from "@/pages/PageEditorViewBody.vue";
import Drive from "@/pages/DriveView.vue";

import DashboardProductScans from "@/pages/DashboardProductScans.vue";

import DashboardEx from "@/pages/DashboardViewEx.vue";
import DashboardMain from "@/pages/DashboardViewMain.vue";


import Maintenance from "@/pages/MaintenanceView.vue";
import Error from "@/pages/ErrorView.vue";

import i18n from './i18n'



// import Dashboard3 from "@/pages/DashboardViewMap2.vue";
// import Dashboard2 from "@/pages/DashboardViewMap.vue";
// import Dashboard from "@/pages/DashboardView.vue";

import Login from "@/pages/LoginView.vue";
import ResetPassword from "@/pages/ResetPasswordView.vue";
import ChangePassword from "@/pages/ChangePasswordView.vue";




import VNetworkGraph from "v-network-graph"
import "v-network-graph/lib/style.css"

import Treeselect from '@zanmato/vue3-treeselect'



axios.defaults.withCredentials = true;

// axios.defaults.baseURL = "http://localhost:3004/";
axios.defaults.baseURL = "https://api.b4food.io/";

// eslint-disable-next-line 
// eslint-disable-next-line
// axios.defaults.baseURL =  process?.env?.VUE_APP_API_URL;
//axios.defaults.baseURL =  process?.env?.VUE_APP_API_URL_PROD;

const HOST_MEDIA = "https://files.b4food.io/b4food/images/";
//const HOST_API = "https://api.b4food.io/b4food";

// eslint-disable-next-line 
const HOST_API = process?.env?.VUE_APP_API_URL; // + "b4food";

axios.interceptors.response.use(undefined, function (error) {
    if (error) {
        const originalRequest = error.config;

        if (error.response != undefined) {
            if (error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                store.dispatch("LogOut");
                window.location = "/login"
                return router.push("/login");
            }

            if (error.response.status === 403 && !originalRequest._retry) {
                originalRequest._retry = true;
                store.dispatch("LogOut");
                window.location = "/login"
                // return router.push("/login");
            }
        }
    }
});


const routes = [
    {
        path: "/login",
        component: Login,
        children: [],
    },
    {
        path: "/auth/login",
        component: Login,
        children: [],
    },
    {
        path: "/reset-password",
        component: ResetPassword,
        children: [],
    },
    {
        path: "/reset-password",
        component: ResetPassword,
        children: [],
    },
    {
        path: "/auth/reset-password",

        component: ResetPassword,
        children: [],
    },
    {
        path: "/auth/change-password",

        component: ChangePassword,
        children: [],
    },
    {
        path: "/query",

        component: QueryView,
        children: [],
    },


    {
        path: "/auth/change-password/:id",

        component: ChangePassword,
        children: [],
    },
    {
        path: "/change-password/:id",

        component: ChangePassword,
        children: [],
    },




    {
        path: "/maintenance",
        component: Maintenance,
    },

    {
        path: "/error",
        component: Error,
    },



    {
        path: "/admin/test-screen-size",
        component: TestScreenSize,
    },

    {
        path: "/admin/dashboard",
        component: DashboardMain,
    },

    {
        path: "/admin/flows/:id",
        component: Flow,
    },
    {
        path: "/admin/LCA/:id",
        component: FlowLCA,
    },
    {
        path: "/admin/editor/:id",
        component: PageEditor,
    },
    {
        path: "/admin/body/:id",

        component: PageEditorBody,
        children: [],
    },
    {
        path: "/admin/developer/ui-lists/:id",
        component: DeveloperList,
        children: [],
    },
    {
        path: "/admin/developer/ui-forms/:id",
        component: DeveloperForm,
        children: [],
    },

    {
        path: "/admin/pdf/:id",
        component: Document,
    },
    {
        path: "/admin/editor/forms/:id",
        component: FormEditor,
    },





    {
        path: "/admin/pdf/display/:id",
        component: DocumentDisplay,
    },
    {
        path: "/admin/new-editor/:id",
        component: NewEditor,

    },
    {
        path: "/admin/chart-editor/:id",
        component: ChartEditor,
    },

    {
        path: "/admin",
        component: Admin,
        children: [





            {
                path: "/admin/agenda/:id",
                component: Agenda,
            },

            {
                path: "/admin/hello",
                component: Hello,
            },



            {
                path: "/admin/maps-marker/:id",
                component: MapMarker,
            },


            {
                path: "/admin/maps/:id",
                component: MapHeatMap,
            },

            {
                path: "/admin/kanban/:id",
                component: Kanban,
            },
            {
                path: "/admin/kanban2/:id",
                component: KanbanDynamic,
            },
            {
                path: "/admin/dashboard-kanban/:id",
                component: DashboardKanban,
            },
            {
                path: "/admin/profile",
                component: Profile,
            },

            {
                path: "/admin/drive/:id",
                component: Drive,
            },

            {
                path: "/admin/dashboard/:id",
                component: DashboardEx,
            },




            {
                path: "/admin/diagrams",
                component: Diagram,
            },


            {
                path: "/admin/dashboard/scans/products",
                component: DashboardProductScans,
            },
            {
                path: "/admin/network/:id",
                component: Network,
            },


            {
                path: "/admin/tables",
                component: Table,
            },
            {
                path: "/admin/views/collections/:id",
                component: TableCollection,
            },
            {
                path: "/admin/views/:id",
                component: Table,
            },
            {
                path: "/admin/views-new/:id",
                component: TableNew,
            },
            {
                path: "/admin/weeks/:id",
                component: Week,
            },


            {
                path: "/admin/media/:id",
                component: Media,
            },
            {
                path: "/admin/media",
                component: Media,
            },
            {
                path: "/admin/media-documents/:id",
                component: Media,
            },
            {
                path: "/admin/media-documents",
                component: Media,
            },
            {
                path: "/admin/pages/:id",
                component: Page,
            },
            {
                path: "/admin/wizard/:id/new",
                component: Wizard,
            },
            {
                path: "/admin/wizard/:id",
                component: Wizard,
            },
            {
                path: "/admin/views/batchesEx/:id",
                component: Result,
            },

            {
                path: "/admin/views/raw-product-batches/:id",
                component: Result,
            },

            {
                path: "/admin/views/finished-product-batches/:id",
                component: Result,
            },


            {
                path: "/admin/views/:id/:rid",
                component: Form,
            },


            {
                path: "/admin/batches/:id",
                component: Result,
            },


            {
                path: "/",
                redirect: "/login",
            },
            //   {
            //       path: "/:pathMatch(.*)*",
            //       redirect: "/login",
            //   },
        ],
    },
    // {
    //     path: "/:pathMatch(.*)*",
    //     redirect: "/login"
    // },
];


const router = createRouter({
    history: createWebHistory(),
    routes
})
// let VueApp = createApp(App)

let VueApp = createApp({
    render: () => h(App)
});

VueApp.config.globalProperties.host_media = HOST_MEDIA
VueApp.config.globalProperties.host_api = HOST_API

VueApp.directive('focus', {
    mounted(el) { // When the bound element is inserted into the DOM...
        el.focus() // Focus the element
    }
})



//.use(I18NextVue, { i18next })

//.use(ProCalendar)
i18n(VueApp).component('treeselect', Treeselect).use(router).use(store).use(VNetworkGraph).mount('#app')
